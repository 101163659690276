<template>
    <main>
        <div class="modal fade" id="modalFormulas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Listado de Formulas, Historia No: {{id_historia}}</h5>
                    <button type="button" class="close"  @click="cerrar();" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card mt-2 shadow-none"  v-if="isLoading">
                        <div class="card-body">
                          <content-placeholders :rounded="true">
                            <content-placeholders-heading :img="false" />
                            <content-placeholders-text :lines="3" />
                          </content-placeholders>
                        </div>
                    </div>
                    <div class="alert alert-warning mt-2" v-else-if="!formulas.length">
                        No existen formulas...
                    </div>
                    <div v-else class="card mt-2 border-gray-500 shadow-none" v-for="item in formulas" :key="item.id">
                        <div class="card-header bg-light">
                            <div class="row">
                                <div class="col-4 ">
                                    Formula No. {{item.id}} 
                                </div>
                                <div class="col-8 d-flex justify-content-end">
                                    <span class="text-danger mx-2">{{item.user.name}}</span> ({{item.created_at | formatDateTime}})
                                    <button @click="imprimir(item.id);" class="btn btn-outline-info lift mx-2 shadow-sm btn-sm" >
                                        <i class="fa fa-print fa-fw"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" >
                            <div class="row">
                                <div class="col-md-8 col-sm-12 col-lg-12 font-weight-bold" v-for="formula in item.items" :key="formula.id">
                                    {{ formula.servicio.codigo }}
                                    {{ formula.servicio.descripcion }}
                                    <div class="small text-muted d-none d-md-block">
                                        Indicaciones: {{ formula.indications }}
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-md-8 col-sm-12 col-lg-12">
                                    Observaciones Generales: <span class="text-primary">{{item.observaciones}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light btn-sm" @click="cerrar();"><i class="far fa-times-circle fa-fw"></i> Cerrar</button>
                </div>
                </div>
            </div>
        </div>
        
    </main>
</template>
<script>
import historiaClinicaService from '../../../../services/historiaClinicaService';
import formulaMedicaService from '../../../../services/formulaMedicaService';
import $ from "jquery";
export default {
    data(){
        return {
            id_historia:'',
            formulas:{},
            isLoading:true,
        }
    },
    methods: {
        async  abrir(id){
            this.$nextTick(async () => {
                this.id_historia =id;
                $("#modalFormulas").modal("show");
                this.isLoading = true;
                const response = await formulaMedicaService.showFormulaByHc(id);
                this.formulas= response.data;
                this.isLoading = false;
            });
        },
        imprimir(id_formula){
            window.open(historiaClinicaService.formula(id_formula));
        },
        cerrar(){
            $("#modalFormulas").modal("hide");
            this.formulas= {};
        }
    },
}
</script>