<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>
            <span class="d-block">Historial de historias Clinicas</span>
            <template v-slot:button>
                <router-link :to="{ name: 'historias.perfil.usuario', params: { idUsuario: usuario.id } }"
                    class="btn btn-success btn-sm shadow-sm mx-2">
                        Regresar al perfil del paciente <i class="fas fa-history fa-fw"></i>
                </router-link>
            </template>
        </page-header-compact>
        <div class="container-xl px-4">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <data-grid :data="pagination"
                        @changePage="changePage($event)"
                        @perPageChange="perPageChange($event)"
                        @search="search($event)">
                        <template #grid>
                            <grid-table>
                                <template #head>
                                    <grid-cell-header class="bg-light"  v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                </template>
                                <template #body>
                                    <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                        <grid-cell class="text-center small">
                                            {{item.id}}
                                        </grid-cell>
                                        <grid-cell class="text-center small">
                                            {{item.tipo_historia.nombre}}
                                        </grid-cell>
                                        <grid-cell class="text-center ">
                                            <span>{{item.fecha | formatDate}}</span>
                                        </grid-cell>
                                        <grid-cell class="text-center   ">
                                            <div class="btn-group shadow-sm" role="group" >
                                                <button @click="imprimir(item.id);" class="btn btn-outline-primary  btn-xs" title="Imprimir Historia" >
                                                    <i class="fa fa-print fa-fw"></i> Imprimir
                                                </button>
                                                <button @click="resumen(item.id);" class="btn btn-outline-success  btn-xs" title="Resumen" >
                                                    <i class="fa fa-print fa-fw "></i> Resumen
                                                </button>
                                                <button v-if="item.formulas.length" @click="formulaModal(item.id);" class="btn btn-outline-info  btn-xs" title="Fórmula médica" >
                                                    <i class="fa fa-print fa-fw "></i> Fórmulas
                                                </button>
                                                <button v-if="item.ordenes.length " @click="ordenModal(item.id,'procedimiento');" class="btn btn-outline-secondary  btn-xs" title="Orden médica" >
                                                    <i class="fa fa-print fa-fw "></i> Orden Procedimiento
                                                </button>
                                                <button v-if="item.ordenes.length && item.tipo_historia.codigo==='URG'" @click="ordenModal(item.id,'medicamento');" class="btn btn-outline-secondary  btn-xs" title="Orden médica" >
                                                    <i class="fa fa-print fa-fw "></i> Orden Medicamento
                                                </button>
                                                <button v-if="item.remisiones.length" type="button" class="btn btn-outline-dark  btn-xs" title="Remisiones" @click="abrirModal(item.id)">
                                                <i class="fa fa-print fa-fw "></i> Remisiones
                                                </button>
                                                <button v-if="item.evoluciones.length" type="button" class="btn btn-outline-warning   btn-xs" title="Evoluciones" @click="EvolucionModal(item.id)">
                                                    <i class="fa fa-print fa-fw "></i> Evoluciones
                                                </button>
                                            </div>
                                            <!-- <span class="badge badge-warning" v-if="item.finalizada==='N'">No finalizado</span> -->
                                        </grid-cell>
                                    </grid-row>
                                </template>
                            </grid-table>
                        </template>
                    </data-grid>
                    <modal-remisiones ref="modalRemisiones"></modal-remisiones>
                    <modal-ordenes ref="modalOrdenes"></modal-ordenes>
                    <modal-formulas ref="modalFormulas"></modal-formulas>
                    <modal-evoluciones ref="modalEvoluciones"></modal-evoluciones>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import usuarioService from '../../../../services/usuarioService';
import historiaClinicaService from '../../../../services/historiaClinicaService';

import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../../components/common/utilities/DataGrid/GridCell";
import Toast from '../../../../components/common/utilities/toast';
import modalRemisiones from "./modalRemisiones";
import modalOrdenes from "./modalOrdenes";
import modalFormulas from "./modalFormulas";
import modalEvoluciones from "./modalEvoluciones";

export default {
    components: {modalInformacionUsuario,cardInformacionUsuario,PageHeaderCompact,
    GridCell, GridRow, GridCellHeader, GridTable, DataGrid,modalRemisiones,modalOrdenes,modalFormulas,modalEvoluciones},
    data(){
        return {
            id_usuario:'',
            usuario: {},
            historias: [],
            rutas_imprimir:{
                'I':'historias.infancia.print',
                'EMB':'historias.embarazo.print',
                'AD':'historias.adolescente.print',
                'JUV':'historias.juventud.print',
                'ADU':'historias.adulto.print',
            },
            headers : [
               '#',
               'Tipo de historia',
               'Fecha',
               'Opciones'
            ],
            pagination : {
                data : [],
                current_page : 1,
                page : 1,
                per_page : 5,
                search : '',
                total: '',
            }
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarHistorias(id){
             try {
                this.LoaderSpinnerShow();
                const response = (await historiaClinicaService.showByIdPaciente(this.pagination.search, this.pagination,id))?.data;
                this.pagination.data = response.data;
                this.pagination.current_page  = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
                
            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        imprimir(id){
            window.open(historiaClinicaService.print(id));
            //this.$router.push({name : this.rutas_imprimir[tipo], params:{id:id}});
        },
        resumen(id){
            window.open(historiaClinicaService.resumen(id));
        },
        /* formula(id){
            window.open(historiaClinicaService.formula(id));
        },
        orden(id){
            window.open(historiaClinicaService.orden(id));
        }, */
        changePage(page) {
            this.pagination.page = page;
            this.cargarHistorias(this.id_usuario);
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.cargarHistorias(this.id_usuario);
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.cargarHistorias(this.id_usuario);
        },
        abrirModal(id_historia){ 
            this.$refs.modalRemisiones.abrir(id_historia);
        },
        formulaModal(id_historia){
            this.$refs.modalFormulas.abrir(id_historia);
        },
        ordenModal(id_historia,type){
            this.$refs.modalOrdenes.abrir(id_historia,type);
        },
        EvolucionModal(id_historia){
            this.$refs.modalEvoluciones.abrir(id_historia);
        }
    },
    async created(){
        this.id_usuario=this.$route.params.id;
        await this.cargarUsuario(this.$route.params.id);
        await this.cargarHistorias(this.$route.params.id);
    }
}
</script>